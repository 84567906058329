<template>
  <ib-tooltip
    v-if="isShow"
    :content="`${$t('openThisSectionInBusinessPlan')}`"
  >
    <router-link
      class="open-business-plan-btn d-flex align-items-center"
      :to="{name: 'business-plan', query: {section: sectionId}}"
    >
      <span class="font-outfit-regular">{{ $t('openPlan') }}</span> <i class="ib-icon ib-icon-double-right" />
    </router-link>
  </ib-tooltip>
</template>

<script>
import MixinUserSubscription from '@/mixins/permissions/userSubscription'

export default {
  name: 'OpenBusinessPlan',

  mixins: [MixinUserSubscription],

  props: {
    sectionId: {
      type: Number,
      required: true
    }
  },

  computed: {
    isShow () {
      if (this.subscriptionIsFreeTrial) {
        return [
          'business-guide-concept-idea',
          'business-guide-concept-product',
          'business-guide-concept-promotion',
          'business-guide-concept-distribution',
          'business-guide-concept-partners'
        ].includes(this.$route.name)
      }

      return true
    }
  }
}
</script>

<style scoped lang="scss">
.open-business-plan-btn {
  min-width: 100px;
  text-decoration: none;
  color: #0E64E6;
  border-radius: 8px;
  border: 1px solid #0E64E6;
  background-color: $color-white;
  height: 30px;
  font-size: 12px;
  padding: 0 10px;
  transition: background-color 0.25s ease;

  &:hover {
    background-color: rgb(14, 99, 230, 0.1);
  }
  &:active {
    background-color: rgba(14, 99, 230, 0.20);
  }

  .ib-icon {
    font-size: 16px;
    margin-left: 2px;
  }
}
</style>
