<template>
  <!-- Step -->

  <div
    :class="{
      active:
        stepData['name'] === activeStep ||
        categoryItemNotSelected ||
        (stepData['name'] !== activeStep && categoryItemNotSelected),
      'active-no-child-selected':
        stepData['name'] === activeStep && !categoryItemNotSelected,
      'nav-category': !withoutNavClass
    }"
    @click="handleClick"
    @mouseenter="toggleStep"
    @mouseleave="$emit('toggle-step', '')"
  >

    <!-- Step Wrapper -->
    <div
      class="category-wrapper"
      :class="{
        completed:
          completedStepsCount === stepData.items.filter(step => step.enabled === true).length && stepData.items.filter(step => step.enabled === true).length > 0
      }"
    >

      <!-- Title -->
      <p class="category-title">
        {{ stepData.title }}
        <svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362" viewBox="0 0 292.362 292.362" v-bind:class="'caret-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"/></svg>
      </p>
      <!-- /Title -->

      <!-- Counter -->
      <span
        class="category-count"
        :class="{
          hidden:
            completedStepsCount === stepData.items.length ||
            showCounter === false
        }"
      >
        {{ completedStepsCount }}/{{
          stepData.items.filter(step => step.enabled === true).length
        }}
      </span>
      <!-- /Counter -->

    </div>
    <!-- /Step Wrapper -->

    <!-- Dropdown -->
    <transition name="fade">
      <div
        v-if="stepData['name'] === activeStep"
        v-on-clickaway="awaySettings"
        class="category-dropdown"
        @mouseleave="$emit('toggle-step', '')"
      >

        <template v-for="item in stepData.items.filter(
          stepItem => stepItem.enabled === true
        )"
        >
          <!-- Router Link -->
          <component
            :is="subscriptionIsFreeTrial && item.paid ? 'span' : 'router-link'"
            :key="item.name"
            :to="{ name: `business-guide-${stepData.name}-${item.name}` }"
            @click="setDialogUpgradeState(true)"
          >
            <div
              class="category-item"
              :class="{
                active:
                  $route.name === `business-guide-${stepData.name}-${item.name}`
              }"
            >

              <!-- Icon & Title -->
              <div class="left">

                <!-- Icon -->
                <i class="ib-icon h2 mr-2 d-none d-lg-block" :class="item.icon" />
                <!-- /Icon -->

                <!-- Title -->
                <p class="item-title ml-1">
                  {{ item.title }}
                  <template v-if="subscriptionIsFreeTrial && item.paid">
                    <i class="ib-icon ib-icon-lock-2 o-50 h5" />
                  </template>
                </p>
                <!-- /Title -->

              </div>
              <!-- /Icon & Title -->

              <!-- Checkbox -->
              <div
                v-if="item.completedStatus"
                class="pseudo-checkbox"
                :class="{
                  checked:
                    $store.state.idea.storyMode.common.completedSteps[
                      item.completedStepName
                    ]
                }"
              >
                <i class="el-icon-check" />
              </div>
              <!-- /Checkbox -->

            </div>
          </component>
          <!-- /Router Link -->
        </template>

        <!-- Disabled Items -->
        <a
          v-for="item in stepData.items.filter(
            stepItem => stepItem.enabled === false
          )"
          :key="item.name"
          class="disabled-link"
        >
          <div
            class="category-item"
            :class="{
              active:
                $route.name === `business-guide-${stepData.name}-${item.name}`
            }"
          >
            <!-- Icon & Title -->
            <div class="left">
              <!-- Icon -->
              <i class="ib-icon h2 mr-2" :class="item.icon" />
              <!-- /Icon -->

              <!-- Title -->
              <p class="item-title ml-1 is-disabled">
                {{ item.title }} <span class="soon">soon</span>
              </p>
            </div>
            <!-- /Icon & Title -->

            <!-- Checkbox -->
            <div
              v-if="item.completedStatus"
              class="pseudo-checkbox"
              :class="{
                checked:
                  $store.state.idea.storyMode.common.completedSteps[
                    item.completedStepName
                  ]
              }"
            >
              <i class="el-icon-check" />
            </div>
            <!-- /Checkbox -->
          </div>
        </a>
        <!-- /Disabled Items -->
      </div>
    </transition>
    <!-- /Dropdown -->

  </div>
  <!-- /Step -->

</template>

<script>
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import { directive as onClickaway } from 'vue-clickaway'

import { mapMutations } from 'vuex'

export default {
  name: 'Step',

  directives: {
    onClickaway: onClickaway
  },

  mixins: [MixinUserSubscription],

  props: {
    stepData: {
      type: Object,
      default: () => ({
        name: ''
      })
    },

    activeStep: {
      type: String,
      default: ''
    },

    showCounter: {
      type: Boolean,
      default: true
    },

    withoutNavClass: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      categoryItemNotSelected: false
    }
  },

  computed: {
    completedStepsCount () {
      let count = 0
      const completedNames = this.stepData.items.map(a => a.completedStepName)

      for (let i = 0; i < completedNames.length; i++) {
        if (this.$store.state.idea.storyMode.common.completedSteps[completedNames[i]] === true) {
          count++
        }
      }

      return count
    }
  },

  created () {
    this.stepData.items.forEach(item => {
      if (this.$route.name === `business-guide-${this.stepData.name}-${item.name}`) {
        this.categoryItemNotSelected = true
      }
    })
  },

  methods: {
    ...mapMutations('ui', ['setDialogUpgradeState']),

    handleClick () {
      if (this.$isTouchDevice) {
        this.$emit('toggle-step', this.stepData.name)
      }
    },

    toggleStep () {
      if (!this.$isTouchDevice) {
        this.$emit('toggle-step', this.stepData.name)
      }
    },

    awaySettings () {
      this.$emit('away-settings')
    }
  }
}
</script>
